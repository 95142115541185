@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  	width: 100%;
}

body {
	background: #EEF3F5;
	font-family: 'Open Sans', sans-serif;
}

#root {
	display: flex;
	justify-content: center;
}

a {
	text-decoration: none !important;
}

a:hover {
	text-decoration: underline;
}

.d-hidden{
	visibility: hidden;
}